<template>
    <section id="sobrenosotros" class=" py-44 bg-gray-100 bg-gradient-to-b from-[#979499] to-[#f3f0e9]">
      <div class="container mx-auto px-4 ">
        <h2 class="text-3xl text-[#c9bba5] font-bold text-center mb-10">Sobre Nosotros</h2>
        <div class="flex flex-col md:flex-row items-center">
          <img src="@/assets/images/nosotros.jpg" alt="Clínica Dental" class="w-full md:w-1/2 rounded-lg shadow-lg mb-6 md:mb-0">
          <div class="md:ml-8">
            <p class="text-gray-700 mb-4">
              Nuestra clínica dental ofrece un servicio integral de cuidado oral, utilizando tecnología de vanguardia y contando con un equipo de profesionales altamente calificados.
            </p>
            <p class="text-gray-700">
              Desde el cuidado preventivo hasta procedimientos avanzados, estamos aquí para mejorar y mantener su salud dental.
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutUs',
  };
  </script>
  
  <style scoped>
  
  </style>
 

 

  
  
  
  
  
  
  
  
  