
<template>
  <section id="nuestroequipo" class="py-44 bg-gray-100 bg-gradient-to-b from-[#979499] to-[#f3f0e9]">
    <div class="container mx-auto px-4 font-chillax">
      <h2 class="text-3xl text-[#c9bba5] font-bold text-center mb-10">Conoce a Nuestro Equipo</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 ">
        <div class="bg-white p-6 rounded-lg shadow-lg bg-gradient-to-b from-[#CCC9CD] to-[#f3f0e9] ">
          <img src="@/assets/images/krizia.jpg" alt="Dra.KriziaCasanovas" class="w-32 mx-auto rounded-full mb-4">
          <h3 class="text-xl font-bold text-center font-chillax">Dra.Krizia Casasnovas</h3>
          <h2 class="text-center text-gray-700 font-semibold text-lg font-switzer">Especialista en estética Dental y prostodoncia</h2>
          <p class="text-justify text-gray-700 font-switzer"><br>•Licenciada en Odontología. Universidad de Carabobo (UC), Venezuela.
            <br>•Máster oficial en prótesis, implantoprótesis y estética dental avanzada. Universidad Europea de Madrid (UEM).<br>•Diplomada en operatoria y estética dental mínimamente invasiva. Universidad Central de Venezuela (UCV).
            <br>•Experta en estética labial y perioral. Dra.Carla Barber
            <br>•Miembro de la sociedad española de prótesis estomatológica y estética dental (SEPES).
            <br>•Miembro de la sociedad venezolana de operatoria dental, estética y biomateriales (SVODEB)</p>
       
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg bg-gradient-to-b from-[#CCC9CD] to-[#f3f0e9]">
          <img src="@/assets/images/gutierrez.jpg" alt="Dr.Juan Carlos Gutierrez" class="w-32 mx-auto rounded-full mb-4">
          <h3 class="text-xl font-bold text-center font-chillax">Dr. Juan Carlos Gutierrez</h3>
          <h2 class="text-center text-gray-700 font-semibold text-lg font-switzer">Especialista en Implantes</h2>
          <p class="text-justify text-gray-700 font-switzer"><br>•Licenciado en Odontología Univ Europea de Madrid 2005-2010
            <br>•Master Propio en Cirugía bucal Universidad de Sevilla
            <br>•Máster Propio en Cirugía Bucal Avanzada Universidad de Sevilla
            <br>•Postgrado en Implantologia Univ Complutense de Madrid.
            <br>•Experto en Anatomía Oral aplicada a la Implantología e Implantoprotesis por la Universidad de Sevilla
            <br>•Título de especialización Universitaria en Cirugía y Rehabilitación Implantología por la Universidad de Sevilla.
            <br>•Comunicaciones en congresos y publicaciones en revistas de alto impacto.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg bg-gradient-to-b from-[#CCC9CD] to-[#f3f0e9]">
          <img src="@/assets/images/eli.jpg" alt="Elisabeth Benitez" class="w-32 mx-auto rounded-full mb-4">
          <h3 class="text-xl font-bold text-center font-chillax">Elisabeth Benitez</h3>
          <h2 class="text-center text-gray-700 font-semibold text-lg font-switzer">Higienista</h2>
          <p class="text-justify text-gray-700"><br>•Técnico en Higiene bucodental Ecodents.<br>•Atención al paciente<br>•Riesgos laborales<br> </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurTeam',
};

</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>