<template>
  <footer class="bg-gray-800 text-white py-8">
    <div class="container mx-auto px-2 sm:px-4 lg:px-6">
      <div class="flex flex-col sm:flex-row justify-between items-center">
        <div class="text-center sm:text-left mb-4 sm:mb-0">
          <p class="font-chillax text-xl sm:text-2xl mb-2">&copy; 2024 Clínica Casasnovas</p>
          <p class="font-chillax text-sm sm:text-base">Todos los derechos reservados</p>
        </div>
         <div class="text-center sm:text-right">
           <p class="font-chillax text-lg sm:text-xl mb-2">Designed by:</p>
             <a href="https://sierrama1.github.io/" target="_blank" rel="Desarrolladora Web" class="flex items-center          justify-center sm:justify-end">
               <img src="@/assets/images/logoMaria.png" alt="Logo de María Sierra Sánchez" class="w-20 h-20 mr-2">
               <span class="font-chillax text-base sm:text-lg hover:underline font-bold">María Sierra Sánchez</span>
             </a>
          </div>
      </div>
      <div class="mt-6 text-center sm:text-right text-xs sm:text-sm">
        <p class="mb-2">Iconos por:</p>
        <div class="flex flex-col sm:flex-row justify-center sm:justify-end space-y-1 sm:space-y-0 sm:space-x-2">
          <a href="https://www.freepik.es/icono/whatsapp_3670051#fromView=author&page=1&position=6&uuid=2d006a6b-2d7b-4cf0-8ff6-ccf2648f6b95" class="hover:underline" target="_blank" rel="noopener noreferrer">Freepik (WhatsApp)</a>
          <a href="@heroicons/vue/24/solid" class="hover:underline" target="_blank" rel="noopener noreferrer">Heroicons</a>
          <a href="https://www.freepik.es/vector-gratis/instagram-nuevo-icono_954290.htm#fromView=keyword&page=1&position=0&uuid=ad309c06-1be6-4900-98c9-2e2bb5b80655" class="hover:underline" target="_blank" rel="noopener noreferrer">Freepik (Instagram)</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FinalPage',
};
</script>
  
  <style scoped>
 
  </style> 